import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "root-container" }
const _hoisted_2 = { class: "contents" }
const _hoisted_3 = {
  id: "content",
  class: "container"
}
const _hoisted_4 = { class: "footer" }
const _hoisted_5 = { class: "container has-text-centered" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Confirm = _resolveComponent("Confirm")!
  const _component_Prompt = _resolveComponent("Prompt")!
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Loading),
    _createVNode(_component_Confirm),
    _createVNode(_component_Prompt),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_NavBar, { class: "header" }),
      _createVNode(_component_router_view, { name: "butterBar" }),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("section", _hoisted_3, [
          _createVNode(_component_router_view)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "container has-text-centered" }, [
          _createElementVNode("a", { href: "https://twitter.com/haloruns" }, "Twitter"),
          _createTextVNode("   |   "),
          _createElementVNode("a", { href: "https://youtube.com/haloruns" }, "YouTube")
        ], -1)),
        _createElementVNode("div", _hoisted_5, [
          _cache[1] || (_cache[1] = _createElementVNode("a", { href: "/privacy.html" }, "Privacy", -1)),
          _cache[2] || (_cache[2] = _createTextVNode("   |   ")),
          _cache[3] || (_cache[3] = _createElementVNode("a", { href: "/terms.html" }, "Terms", -1)),
          _cache[4] || (_cache[4] = _createTextVNode("   |   ")),
          _createElementVNode("span", {
            class: "version-stamp",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.versionClick && _ctx.versionClick(...args)))
          }, " v:" + _toDisplayString(_ctx.getHash), 1)
        ])
      ])
    ])
  ], 64))
}