import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "submit-butter-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "container" }, [
      _createTextVNode(" As of March 1st, HaloRuns is longer accepting submissions from twitch.tv. Please "),
      _createElementVNode("a", {
        href: "https://help.twitch.tv/s/article/video-on-demand?language=en_US#export",
        rel: "noopener",
        target: "_blank"
      }, "export/upload"),
      _createTextVNode(" videos to a more permanent hosting site such as YouTube. "),
      _createElementVNode("br"),
      _createTextVNode(" This action is being taken due to Twitch's past and present actions of deleting old videos with little notice. ")
    ], -1)
  ])))
}