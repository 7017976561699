import { AuthenticationRequest, AuthService, AuthType, Claim } from "@/api";
import Configuration from "@/config";
import jwt_decode from "jwt-decode"
import store from ".";

export interface ResetRequest {
    Username: string;
}

export interface AuthState {
    jwt: string | null;
    claims: any;
    isAuthenticated: boolean;
    username: string;
    hrClaimStrings: string[];
    refreshHandle: number;
}

export default class AuthStore {
    static pendingAuth: Promise<void> = Promise.resolve();

    // This relies on 1) the enum entries being in order as defined in C# and 2) that the C# definitions are properly ascending powers of 2
    static claimMap = Object.values(Claim).reduce((m, c, i) => { m[c] = i == 0 ? 0 : 1 << (i - 1); return m; }, {} as { [name: string]: number });

    static adminClaims = [Claim.USERS_READ_WRITE, Claim.APPROVE_GLOBAL, Claim.APPROVE_LIMITED, Claim.EDIT_GAMES_GLOBAL, Claim.EDIT_GAMES_LIMITED, Claim.MANAGE_PERMISSIONS, Claim.MODIFY_STREAM_LIST];

    static useToken(jwt: string | null, refresh: (() => Promise<void>) | null) {
        let state = { jwt } as AuthState;

        if (jwt != null) {
            state.claims = jwt_decode(jwt);
            state.isAuthenticated = true;
            state.username = state.claims.name;
            state.hrClaimStrings = new Array();

            for (var [claimString, claim] of Object.entries(AuthStore.claimMap)) {
                if ((state.claims.hrClaims & claim) == claim) {
                    state.hrClaimStrings.push(claimString);
                }
            }

            // store sentinel value to check if we should try to auth on page load
            window.localStorage["authenticated"] = "true";

            if (refresh != null) {
                const expiration = new Date(state.claims.exp * 1000);
                const buffer = 5; // minutes
                expiration.setMinutes(expiration.getMinutes() - buffer);
                let delay = expiration.getTime() - Date.now();
                // No faster refresh than 60 seconds, and sanity check for negative delay
                delay = Math.max(60 * 1000, Math.abs(delay));

                state.refreshHandle = setTimeout(refresh, delay);
            }
        } else {
            window.localStorage["authenticated"] = undefined;
            state.isAuthenticated = false;
            state.username = "Anonymous";
            state.claims = {};
            state.hrClaimStrings = new Array();
        }

        store.commit("authenticate", state);
    }

    static hasClaim(claim: string) {
        return store.state.auth.hrClaimStrings.indexOf(claim) >= 0;
    }

    static hasAnyClaim(claims: string[]) {
        for (let claim of claims) {
            if (AuthStore.hasClaim(claim)) {
                return true;
            }
        }

        return false;
    }

    static hasAdminClaim() {
        return AuthStore.hasAnyClaim(AuthStore.adminClaims);
    }

    static async login(authReq: AuthenticationRequest): Promise<boolean> {
        let resolver = () => { };
        AuthStore.pendingAuth = new Promise(function (resolve, reject) {
            resolver = resolve
        });

        try {
            let jwt = await AuthService.authenticate(authReq);
            AuthStore.useToken(jwt, AuthStore.refresh);
            resolver();
            return true;
        } catch {
            resolver();
            return false;
        }
    }

    static async logout() {
        await AuthService.logout();

        AuthStore.useToken(null, null);
    }

    static async refresh() {

        let resolver = () => { };
        let rejector = () => { };

        AuthStore.pendingAuth = new Promise(function (resolve, reject) {
            resolver = resolve
            rejector = reject;
        });

        // If the sentinel value is not present, don't make an Auth call - user has not previously logged in
        if (window.localStorage["authenticated"] != "true") {
            //rejector();
            return;
        }

        try {
            let jwt = await AuthService.authenticate({ AuthType: AuthType.REFRESH_TOKEN });
            AuthStore.useToken(jwt, AuthStore.refresh);
            resolver();
        } catch {
            rejector()
        }
    }
}