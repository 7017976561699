import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "selector hr-tile" }
const _hoisted_2 = { class: "breadcrumbs" }
const _hoisted_3 = { class: "breadcrumb-item" }
const _hoisted_4 = { class: "hr-tile greeble-tr" }
const _hoisted_5 = { class: "has-text-right" }
const _hoisted_6 = { class: "graph" }
const _hoisted_7 = {
  key: 0,
  class: "hr-tile no-pad"
}
const _hoisted_8 = { class: "filters has-text-right" }
const _hoisted_9 = ["for"]
const _hoisted_10 = ["onUpdate:modelValue", "id"]
const _hoisted_11 = ["value"]
const _hoisted_12 = {
  key: 1,
  class: "leaderboards"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "hr-tile" }
const _hoisted_15 = { class: "title is-4" }
const _hoisted_16 = { class: "hr-tile" }
const _hoisted_17 = { class: "title is-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_leaderboard_key_selector = _resolveComponent("leaderboard-key-selector")!
  const _component_run_graph = _resolveComponent("run-graph")!
  const _component_run_board = _resolveComponent("run-board")!
  const _directive_masonry = _resolveDirective("masonry")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        _createElementVNode("li", _hoisted_3, [
          _createVNode(_component_router_link, { to: '/' }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Home")
            ])),
            _: 1
          })
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("li", { class: "breadcrumb-item" }, [
          _createElementVNode("span", null, "Leaderboards")
        ], -1)),
        (_ctx.leaderboardKey)
          ? (_openBlock(), _createBlock(_component_leaderboard_key_selector, {
              key: 0,
              selectionContainer: _ctx.leaderboardKey,
              onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.load())),
              showDifficulty: _ctx.$store.state.windowWidth <= 1215
            }, null, 8, ["selectionContainer", "showDifficulty"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("small", null, [
          _createVNode(_component_router_link, {
            to: {name: 'Record History', params: _ctx.$route.params}
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Full Record History >")
            ])),
            _: 1
          }, 8, ["to"])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_run_graph, {
          items: _ctx.history,
          class: "graph-container"
        }, null, 8, ["items"])
      ])
    ]),
    (_ctx.availableFilters && _ctx.availableFilters.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableFilters, (filter) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "select",
                key: filter.Name
              }, [
                _createElementVNode("label", {
                  for: 'filter'+filter.Name
                }, _toDisplayString(filter.Name), 9, _hoisted_9),
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": ($event: any) => ((_ctx.filters[filter.Name]) = $event),
                  onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.applyFilters && _ctx.applyFilters(...args))),
                  id: 'filter'+filter.Name
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filter.Values, (val) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: val,
                      value: val
                    }, _toDisplayString(val ?? "Any"), 9, _hoisted_11))
                  }), 128))
                ], 40, _hoisted_10), [
                  [_vModelSelect, _ctx.filters[filter.Name]]
                ])
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.game)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_12, [
          (_ctx.$store.state.windowWidth <= 1215)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("h4", _hoisted_15, _toDisplayString(_ctx.getDifficultyName(_ctx.leaderboardKey.Difficulty)), 1),
                  _createVNode(_component_run_board, {
                    runs: _ctx.runs[_ctx.getDifficultyName(_ctx.leaderboardKey.Difficulty)],
                    filters: _ctx.filters
                  }, null, 8, ["runs", "filters"])
                ])
              ]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.category)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.category.Difficulties, (diff) => {
                      return (_openBlock(), _createElementBlock("div", { key: diff }, [
                        _createElementVNode("div", _hoisted_16, [
                          _createElementVNode("h4", _hoisted_17, _toDisplayString(_ctx.getDifficultyName(diff)), 1),
                          _createVNode(_component_run_board, {
                            runs: _ctx.runs[_ctx.getDifficultyName(diff)],
                            filters: _ctx.filters
                          }, null, 8, ["runs", "filters"])
                        ])
                      ]))
                    }), 128))
                  : _createCommentVNode("", true)
              ], 64))
        ])), [
          [_directive_masonry, _ctx.$store.state.windowWidth <= 1215 ? 1 : 2, _ctx.$store.state.windowWidth]
        ])
      : _createCommentVNode("", true)
  ], 64))
}