import { createRouter, createWebHistory, RouteRecordRaw, RouterView } from 'vue-router'
import Home from '../views/Home.vue'
import Rules from '../views/Rules.vue'
import Calc from '../views/Calc.vue'
import Profile from '../views/Profile.vue'
import UserHistory from "@/components/User/UserHistory.vue"
import UserSummary from "@/components/User/UserSummary.vue"
import UserCareer from "@/components/User/UserCareer.vue"
import RecentDetails from "@/components/User/RecentDetails.vue"
import UserSegmentHistory from "@/components/User/UserSegmentHistory.vue"
import Leaderboards from '@/views/Leaderboards.vue'
import RecordHistory from '@/views/RecordHistory.vue'
import Extensions from '@/views/Extensions.vue'
import OldestRecords from "@/views/OldestRecords.vue"
import RecentRecords from "@/views/RecentRecords.vue"
import Login from '@/views/Auth/Login.vue'
import Register from '@/views/Auth/Register.vue'
import ConfirmRegistration from '@/views/Auth/ConfirmRegistration.vue'
import ResetPassword from '@/views/Auth/ResetPassword.vue'
import ForgotPassword from '@/views/Auth/ForgotPassword.vue'
import ChangeUsername from '@/views/Auth/ChangeUsername.vue'
import Submit from '@/views/Submit.vue'
import SubmitSimple from '@/views/SubmitSimple.vue'
import DiscordAuth from '@/components/Integrations/DiscordAuth.vue'
import TwitchAuth from '@/components/Integrations/TwitchAuth.vue'
import SteamAuth from '@/components/Integrations/SteamAuth.vue'
import XboxAuth from '@/components/Integrations/XboxAuth.vue'
import YoutubeAuth from '@/components/Integrations/YoutubeAuth.vue'
import EditRun from '@/views/EditRun.vue'
import AuthStore from '@/store/auth'
import store from '@/store'
import { Claim } from '@/api'
import { useToast } from 'vue-toastification'
import UserSettings from '@/components/User/UserSettings.vue'
import PostYearCarnageReportPage from '@/views/PostYearCarnageReport.vue'
import SubmitButterBar from '@/components/Submit/SubmitButterBar.vue'

export const SubmitFormPrefPersistenceKey = "preferredForm";

const toast = useToast();


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: "HaloRuns - The Home of Halo Speedrunning"
    }
  },
  {
    path: '/discord',
    name: 'Discord Invite',
    component: Home,
    beforeEnter: (t,f,n) => { 
      let opened = window.open(
        'https://discord.gg/kv9TT5cuJk',
        '_blank');

      // If we couldn't pop a tab, it's likely because the user 'Open Link in New Tab'ed the url, let's just go there directly
      if(!opened) {
        window.location.href = 'https://discord.gg/kv9TT5cuJk';
      }
    },
    meta: {
      title: "HaloRuns Discord"
    }
  },
  {
    path: '/rules',
    name: 'Rules',
    component: Rules,
    meta: {
      title: "HaloRuns - Site and Game Rules"
    }
  },
  {
    path: '/calc/:game?/:category?',
    name: 'Game Calculators',
    component: Calc,
    meta: {
      title: "HaloRuns - Game Calculators"
    }
  },
  {
    path: '/leaderboards/:game?/:category?/:level?/:difficulty?',
    name: 'Leaderboards',
    component: Leaderboards,
    meta: {
      title: "HaloRuns - Run Leaderboards"
    }
  },
  {
    path: '/leaderboards/:game?/:category?/:level?/:difficulty?/history',
    name: 'Record History',
    component: RecordHistory,
    meta: {
      title: "HaloRuns - Record History"
    }
  },
  {
    path: '/extras',
    name: 'Extras',
    component: Extensions,
    meta: {
      title: "HaloRuns - Extra Categories"
    }
  },
  {
    path: '/extras/request',
    name: 'Request Extras Category',
    component: Extensions
  },
  {
    path: '/records/oldest',
    name: 'Oldest Records',
    component: OldestRecords,
    meta: {
      title: "HaloRuns - Oldest Records"
    }
  },
  {
    path: '/records/recent',
    name: 'Recent Records',
    component: RecentRecords,
    meta: {
      title: "HaloRuns - Recent Records"
    }
  },
  {
    path: '/auth',
    name: 'Auth',
    meta: {
      title: "HaloRuns - Authentication"
    },
    component: RouterView,
    beforeEnter: (t,f,n) => t.path == "/auth" ? n({ path: "/auth/login" }) : n(),
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login,
        meta: {
          title: "HaloRuns - User Authentication"
        }
      },
      {
        path: 'register',
        name: 'Register',
        component: Register,
        meta: {
          title: "HaloRuns - User Registration"
        }
      },
      {
        path: 'forgot',
        name: 'Forgot Password',
        component: ForgotPassword,
        meta: {
          title: "HaloRuns - Forgot Password"
        }
      },
      {
        path: 'reset',
        name: 'Reset Password',
        component: ResetPassword,
        meta: {
          title: "HaloRuns - Reset Password"
        }
      },
      {
        path: 'confirm',
        name: 'Account Confirmation',
        component: ConfirmRegistration,
        meta: {
          title: "HaloRuns - Account Confirmation"
        }
      },
      {
        path: 'username',
        name: 'Change Username',
        component: ChangeUsername,
        meta: {
          title: "HaloRuns - Change Username"
        }
      },
    ]
  },
  {
    path: '/runners',
    name: 'Runners',
    component: () => import('../views/Runners.vue')
  },
  {
    path: '/integrate/discord',
    component: DiscordAuth,
    meta: {
      requireClaim: Claim.SELF_READ_WRITE
    }
  },
  {
    path: '/integrate/twitch',
    component: TwitchAuth,
    meta: {
      requireClaim: Claim.SELF_READ_WRITE
    }
  },
  {
    path: '/integrate/steam',
    component: SteamAuth,
    meta: {
      requireClaim: Claim.SELF_READ_WRITE
    }
  },
  {
    path: '/integrate/xbox',
    component: XboxAuth,
    meta: {
      requireClaim: Claim.SELF_READ_WRITE
    }
  },
  {
    path: '/integrate/youtube',
    component: YoutubeAuth,
    meta: {
      requireClaim: Claim.SELF_READ_WRITE
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue'),
    meta: {
      title: "HaloRuns - Admin",
      requireClaim: AuthStore.adminClaims
    },
    children: [
      {
        path: 'commands',
        name: 'Admin Commands',
        component: () => import(/* webpackChunkName: "admin" */ '../components/Admin/Commands.vue'),
      },
      {
        path: 'games',
        name: 'Game Management',
        component: () => import(/* webpackChunkName: "admin" */ '../components/Admin/Games/GameList.vue'),
        meta: {
          requireClaim: [Claim.EDIT_GAMES_GLOBAL, Claim.EDIT_GAMES_LIMITED]
        },
        children: [
          {
            path: ':id',
            name: 'Game Edit',
            component: () => import(/* webpackChunkName: "admin" */ '../components/Admin/Games/GameEdit.vue'),
          }
        ]
      },
      {
        path: 'queue',
        name: 'Submission Queue',
        component: () => import(/* webpackChunkName: "admin" */ '../components/Admin/SubmissionQueue/RunSubmissionQueue.vue'),
        meta: {
          requireClaim: [Claim.APPROVE_GLOBAL, Claim.APPROVE_LIMITED]
        }
      },
      {
        path: 'users',
        name: 'User Management',
        component: () => import(/* webpackChunkName: "admin" */ '../components/Admin/Users/UserList.vue'),
        meta: {
          requireClaim: [Claim.USERS_READ_WRITE, Claim.MANAGE_PERMISSIONS]
        },
        children: [
          {
            path: ':id',
            name: 'User Edit',
            component: () => import(/* webpackChunkName: "admin" */ '../components/Admin/Users/UserEdit.vue'),
          }
        ]
      },
      {
        path: 'badges',
        name: 'Badge Management',
        component: () => import(/* webpackChunkName: "admin" */ '../components/Admin/Badges/BadgeList.vue'),
        meta: {
          requireClaim: [Claim.USERS_READ_WRITE, Claim.MANAGE_USER_AWARDS]
        },
        children: [
            {
            path: ':id',
            name: 'Badge Edit',
            component: () => import(/* webpackChunkName: "admin" */ '../components/Admin/Badges/BadgeEdit.vue'),
            }
        ]
      },
      {
        path: 'streams',
        name: 'Stream Management',
        component: () => import(/* webpackChunkName: "admin" */ '../components/Admin/StreamManagement.vue'),
        meta: {
          requireClaim: Claim.MODIFY_STREAM_LIST
        }
      }
    ]
  },
  {
    path: '/submit/:game?/:category?/:difficulty?',
    name: 'Simple Submit',
    components:{
      default: SubmitSimple,
      butterBar: SubmitButterBar
    },
    meta: {
      title: "HaloRuns - Submit",
      requireClaim: Claim.SUBMIT_RUNS
    },
    beforeEnter: (to, from, next) => {
      const pref = localStorage.getItem("preferredForm") ?? to.name?.toString();

      if(from.name != 'Detailed Submit' && to.name != pref) {
        next({name: pref});
        return;
      }

      localStorage.setItem("preferredForm", to.name!.toString());
      next()
    }
  },
  {
    path: '/submit-detailed/:game?/:category?/:difficulty?',
    name: 'Detailed Submit',
    components:{
      default: Submit,
      butterBar: SubmitButterBar
    },
    meta: {
      title: "HaloRuns - Detailed Submit",
      requireClaim: Claim.SUBMIT_RUNS
    },
    beforeEnter: (to, from, next) => {
      const pref = localStorage.getItem("preferredForm") ?? to.name?.toString();

      if(from.name != 'Simple Submit' && to.name != pref) {
        next({name: pref});
        return;
      }

      localStorage.setItem("preferredForm", to.name!.toString());
      next()
    }
  },
  {
    path: '/profiles/:username',
    name: 'User Profile',
    component: Profile,
    redirect: {name : 'Profile'},
    meta: {
      title: `HaloRuns - User Profile`,
    },
    children: [
      {
        path: '',
        name: 'Profile',
        component: UserSummary
      },
      {
        path: 'career',
        name: 'Career',
        component: UserCareer
      },
      {
        path: 'history',
        name: 'History',
        component: UserHistory,
        children: [
          {
            path: ':gameId/:categoryId/:segmentId',
            name: 'Level History',
            component: UserSegmentHistory
          }
        ]
      },
      {
        path: 'recent',
        name: 'Recent',
        component: RecentDetails
      },
      {
        path: 'settings',
        name: 'UserSettings',
        component: UserSettings
      }
    ]
  },
  {
    path: '/runs/:runId/:partitionKey',
    name: "Edit Run",
    component: EditRun,
    meta: {
      requireClaim: Claim.SELF_READ_WRITE
    }
  },
  {
    path: '/pycr/:year/:userIdOverride?',
    name: 'PYCR',
    component: PostYearCarnageReportPage,
    meta: {
      title: `HaloRuns - Post Year Carnage Report`,
      requireClaim: Claim.SELF_READ_WRITE
    }
  },
  {
    path: '/:catchAll(.*)',
    name: "Not Found",
    redirect: "/"
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((t,f,n) => {
    store.commit("setNav", false);
    n();
});

// Hook for guarding pages by authorization claims
router.beforeEach((to, from, next) => {
  // Check if route requires authorization
  if(to.meta && to.meta.requireClaim) {
    AuthStore.pendingAuth.then(() => {
      if(!store.state.auth.isAuthenticated) {
        next({name: "Login"});
      } else if(typeof(to.meta.requireClaim) === "string" && AuthStore.hasClaim(to.meta.requireClaim)) {
        next();
      } else if(to.meta.requireClaim instanceof Array && AuthStore.hasAnyClaim(to.meta.requireClaim)) {
        next();
      } else {
        toast.warning("Access denied: missing claim " + (to.meta.requireClaim as any).toString());
        next(from);
      }
    });
  } else {
    next();
  }
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = <string>nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = <string>previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode!.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  (<any>nearestWithMeta.meta.metaTags).map((tagDef: any) => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach((tag: any) => document.head.appendChild(tag));

  next();
});

export default router