<template>
  <Loading></Loading>
  <Confirm></Confirm>
  <Prompt></Prompt>
  
  <div class="root-container">
    <NavBar class="header"></NavBar>
    <router-view name="butterBar" />
    <div class="contents">
        <section id="content" class="container">
            <router-view />
        </section>
    </div>
    <div class="footer">
        <div class="container has-text-centered">
            <a href="https://twitter.com/haloruns">Twitter</a> &nbsp; | &nbsp;
            <a href="https://youtube.com/haloruns">YouTube</a>
        </div>
        <div class="container has-text-centered">
            <a href="/privacy.html">Privacy</a> &nbsp; | &nbsp;
            <a href="/terms.html">Terms</a> &nbsp; | &nbsp;
            <span class="version-stamp" @click="versionClick">
                v:{{getHash}}
            </span>
        </div>
    </div>
  </div>
  
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import { mapState } from 'vuex';
import NavBar from '@/components/NavBar.vue';
import Confirm from '@/plugins/Confirm.vue';
import Prompt from '@/plugins/Prompt.vue';
import Configuration from './config';
import { useToast } from "vue-toastification";
import Loading from './plugins/Loading.vue';

@Component({
  components: {
    NavBar,
    Confirm,
    Prompt,
    Loading
  }
})
export default class App extends Vue 
{
  getHash = process.env.VUE_APP_GIT_HASH;
  toast = useToast();

  beforeMount() {
    // Ensure configuration is initialized ASAP
    Configuration.get();
  }

  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  }

  public versionClick(e: MouseEvent) {
    if(e.detail === 3) {
      this.$store.commit("enableDevMode");
      this.toast.info("Enabled dev mode");
    }
  }

  onResize () {
    this.$store.commit("setMobile", window.innerWidth < 768); // same width as Bulma's CSS break point
    this.$store.commit("setMobileSmall", window.innerWidth < 568); // Arbitrary small width
    this.$store.commit("setWindowWidth", window.innerWidth);
  }

  beforeUnmount () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true } as EventListenerOptions)
    }
  }

  get auth() {
    return this.$store.state.auth;
  }
}
</script>



<style lang="scss" scoped>
@use "@/assets/haloruns_vars.scss" as *;

.root-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.header {
    flex:none;
}

.contents {
    flex: 1 0 auto;
}

.footer {
    margin-top: 100px;
    background-color: rgba(0,0,0,0.2);
    border-top: 2px solid $border-color;
    padding: 20px;
    flex: none;
}

.version-stamp {
  color: lighten($body-background-color, 70%);
  cursor: default;
}
</style>

<style lang="scss" src="@/assets/haloruns.scss"></style>
<style lang="scss" src="@/assets/toast/index.scss"></style>
<style lang="scss" src="@fortawesome/fontawesome-free/scss/fontawesome.scss"></style>
<style lang="scss" src="@fortawesome/fontawesome-free/scss/solid.scss"></style>
<style lang="css" src="vue-virtual-scroller/dist/vue-virtual-scroller.css"></style>